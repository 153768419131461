<template>
    <div class="content-wrapper">
        <div v-if="!clientPermission" class="col-12 row justify-content-center middle">
            <button class="btn btn-connect btn-lg" @click="initWindow">Click here to Join</button>
        </div>

        <div v-if="clientPermission" class="user-list">
            <span v-for="(user, uuid) in userList" class="badge badge-pill"
                :class="user.role.toLowerCase(), { 'o-role': $store.getters.getRole == 'ROLE_ADMIN' }"
                @click="changeUserQualitySettings(uuid)">
                {{ user.name }}
            </span>
        </div>

        <div v-if="clientPermission" @mouseover="showStreamControls = true" @mouseleave="showStreamControls = false"
            id="dish" class="Scenary">
            <div v-show="showStreamControls" class="stream-controls">
                <div class="control-icon" @click="toggleWebcam">
                    <svg-icon v-if="!webcamStream" type="mdi" :path="icons['mdiVideo']"></svg-icon>
                    <svg-icon v-if="webcamStream" type="mdi" :path="icons['mdiVideoOff']"></svg-icon>
                </div>
                <div class="control-icon" @click="showVideoSettingsModal">
                    <svg-icon type="mdi" :path="icons['mdiMonitorShare']"></svg-icon>
                </div>
                <div v-if="isStreaming" class="control-icon red-icon" @click="stopMediaStream">
                    <svg-icon type="mdi" :path="icons['mdiMonitorOff']"></svg-icon>
                </div>
                <router-link v-if="!isStreaming" :to="{ name: 'CreateRoom' }"
                    style="text-decoration: none; color: inherit;">
                    <div class="control-icon">
                        <svg-icon type="mdi" :path="icons['mdiPhoneHangup']"></svg-icon>
                    </div>
                </router-link>
            </div>
        </div>

        <VideoSettingsModal v-if="isVideoSettingsModalVisible" @close="closeVideoSettingsModal" />
        <CustomBitrate v-if="isQualitySettingsModalVisible" :uuid="selectedQualityUUID"
            @close="closeQualitySettingsModal" />
    </div>
</template>

<script>

import api from "@/services/api";
import VideoSettingsModal from './VideoSettingsModal.vue'
import CustomBitrate from './CustomBitrate.vue'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMonitorShare } from '@mdi/js';
import { mdiMonitorOff } from '@mdi/js';
import { mdiVideo } from '@mdi/js';
import { mdiVideoOff } from '@mdi/js';
import { mdiPhoneHangup } from '@mdi/js';
import { mdiFullscreenExit } from '@mdi/js';

import store from '@/store';

export default {
    props: ['title'],
    name: 'Header',
    components: {
        VideoSettingsModal, CustomBitrate, SvgIcon
    },
    data() {
        return {
            clientPermission: false,
            isStreaming: false,
            showStreamControls: false,
            isVideoSettingsModalVisible: false,
            isQualitySettingsModalVisible: false,
            userLeft: false,
            selectedQualityUUID: undefined,
            webSocketConnection: undefined,
            peerConnection: undefined,
            dataChannel: undefined,
            pcConfig: undefined,
            localUuid: undefined,
            peerConnections: {},
            userList: {},
            trackIds: [],
            username: undefined,
            localStream: undefined,
            webcamStream: undefined,
            localVideoId: undefined,
            dish: undefined,
            selectedBitrate: undefined,
            selectedFPS: undefined,
            wsTimeout: 50,
            icons: {
                'mdiMonitorShare': mdiMonitorShare, 'mdiMonitorOff': mdiMonitorOff, 'mdiVideo': mdiVideo, 'mdiVideoOff': mdiVideoOff, 'mdiPhoneHangup': mdiPhoneHangup,
                'mdiFullscreenExit': mdiFullscreenExit
            },
            lastYTPlayerTime: -1,
            ytUserIsSeeking: false,
            ytLatestSeekTime: 0,
            ytPaused: false,
            isProgrammaticYT: false,
            programmaticYTTimeout: undefined,
            ytPlaylist: [],
            ytEventListener: undefined
        }
    },
    async mounted() {
        this.loadPcConfig();
        if (!store.getters.isLoggedIn) {
            await this.requestGuestAccount();
        }
        this.localUuid = store.getters.getUUID;
        this.username = store.getters.getUser;

        this.registerYtEventListener();
    },
    beforeUnmount() {
        this.leaveConnection();
    },
    watch: {
        clientPermission(newValue, value) {
            if (newValue) {
                this.$nextTick(function () {
                    this.initVideoDash();
                })
            }
        }
    },
    methods: {
        // sendMessage() {
        //     this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'stopTrack' }));
        // },
        initWindow() {
            this.clientPermission = true;
            this.initWebsocketConnection();
            this.emitter.emit("showYtInput");
            window.onbeforeunload = () => {
                this.leaveConnection();
            }
        },
        initWebsocketConnection() {
            const token = store.getters.getToken;
            let tokenPath = '';
            if (token) {
                tokenPath = "/" + token;
            }
            this.webSocketConnection = new WebSocket(process.env.VUE_APP_WS_URL + "/" + this.localUuid + "/" + this.$route.params.roomId + tokenPath);

            this.webSocketConnection.onclose = event => {
                if (!this.userLeft) {
                    console.log(`WebSocket is closed. Reconnect will be attempted in ${this.wsTimeout}ms.`, event.reason);
                    setTimeout(() => {
                        if (this.wsTimeout < 10000) {
                            this.wsTimeout += this.wsTimeout;
                        }
                        this.initWebsocketConnection();
                    }, this.wsTimeout);
                }
            }

            this.webSocketConnection.onmessage = event => {
                try {
                    this.gotMessageFromServer(event);
                } catch (err) {
                    console.log(err);
                }
            }

            this.webSocketConnection.onopen = event => {
                this.wsTimeout = 50;
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all' }));
            }
        },
        initVideoDash() {
            let scenary = document.getElementById('dish');
            // create dish
            this.dish = new Dish(scenary);
            // set controls (optional)
            //let controls = new Controls(dish, scenary);
            //controls.append();
            // render dish
            this.dish.append();
            // resize the cameras
            this.dish.resize()
            // resize event of window
            window.addEventListener("resize", () => {
                // resize event to dimension cameras
                this.dish.resize();
            });
        },
        expand() {
            this.dish.expand();
        },
        async startScreenCapture() {
            const displayMediaOptions = {
                video: {
                    frameRate: this.selectedFPS,
                    height: 15360,
                    width: 15360
                },
                audio: {
                    autoGainControl: false,
                    channelCount: 2,
                    echoCancellation: false,
                    latency: 0,
                    noiseSuppression: false,
                    sampleRate: 48000,
                    sampleSize: 16,
                    volume: 1.0
                },
            };

            try {
                let newStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
                let lastTrackIds = [];
                if (this.localStream) {
                    this.localStream.getTracks().forEach(track => {
                        lastTrackIds.push(track.id);
                        track.stop();
                    });
                }

                this.localStream = newStream;
                this.trackIds.push(this.localStream.id);

                if (!this.isStreaming) {
                    this.dish.addCamera(this.localUuid, 'local', this.username, 0, true);
                }
                this.dish.video(this.localUuid, 'local', () => { }, false);
                this.dish.disableVolumeControls(this.localUuid);

                const videoElem = document.getElementById('video_' + this.localUuid);
                videoElem.volume = 0;
                videoElem.srcObject = this.localStream;
                videoElem.srcObject.getVideoTracks()[0].addEventListener('ended', () => {
                    this.stopLocalTrack();
                });

                if (this.isStreaming) {
                    this.replaceTrack(lastTrackIds);
                } else {
                    this.isStreaming = true;
                    this.addTrackForAll();
                }
            } catch (err) {
                console.error(err);
            }
        },
        stopMediaStream() {
            this.localStream.getTracks().forEach(track => track.stop());
            this.stopLocalTrack();
        },
        async toggleWebcam() {
            if (this.webcamStream) {
                this.stopWebcam();
            } else {
                this.webcamStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });

                this.dish.addCamera('wc_' + this.localUuid, 'local', this.username, 0, true);
                this.dish.video('wc_' + this.localUuid, 'local', () => { }, false);
                this.dish.disableVolumeControls('wc_' + this.localUuid);

                const videoElem = document.getElementById('video_' + 'wc_' + this.localUuid);
                videoElem.srcObject = this.webcamStream;
                videoElem.srcObject.getVideoTracks()[0].addEventListener('ended', () => {
                    this.stopWebcam();
                });

                this.addTrackForAll(this.webcamStream);
            }
        },
        stopWebcam() {
            //console.log("stop video with track id ", this.webcamStream)
            this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'stopTrack', 'track': this.webcamStream.id }));

            this.dish.removeCameraById('wc_' + this.localUuid);
            this.webcamStream.getTracks().forEach(track => track.stop());
            this.webcamStream = null;
            this.clearEmptySenders();
        },
        async gotMessageFromServer(message) {
            var signal = JSON.parse(message.data);
            var remoteUuid = signal.uuid;

            if (remoteUuid == this.localUuid || (signal.dest != this.localUuid && signal.dest != 'all')) return;

            //console.log(signal)

            if (signal.type == 'heartbeat') {
                this.webSocketConnection.send(JSON.stringify({ 'type': 'heartbeat' }));
                return;
            }

            if (!(remoteUuid in this.userList)) {
                await this.requestNameByUUID(remoteUuid);
            }

            if (signal.type == 'left') {
                this.clearRemoteUser(remoteUuid);
            }

            if (signal.type == 'ready') {
                console.log(`${remoteUuid} is ready`);
                if (this.isStreaming) {
                    await this.addTrack(remoteUuid);
                }
                if (this.webcamStream) {
                    await this.addTrack(remoteUuid, this.webcamStream);
                }
                return;
            }

            if (signal.type == 'yt') {
                this.handleYtMessage(signal);
            }

            if (signal.type == 'stopTrack') {
                console.log(`${remoteUuid} stopped the track ${signal.track}`);
                this.remoteTrackStopped(signal.track);
                return;
            }

            if (signal.type == 'left') {
                console.log(`${remoteUuid} left`);
                this.remoteTrackStoppedUUID(remoteUuid);
                return;
            }

            if (signal.dest == 'all') {
                // set up peer connection object for a newcomer peer
                this.setUpPeer(remoteUuid);
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': remoteUuid, 'isStreaming': this.isStreaming || this.webcamStream }));

            } else if (signal.dest == this.localUuid && !signal.sdp && !signal.ice) {
                // initiate call if we are the newcomer peer
                this.setUpPeer(remoteUuid);
                if (signal.isStreaming) {
                    this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': remoteUuid, 'type': 'ready' }));
                }

            } else if (signal.sdp) {
                console.log("Got signal sdp: " + signal.sdp.type)
                this.peerConnections[remoteUuid].pc.setRemoteDescription(new RTCSessionDescription(signal.sdp)).then(() => {
                    // Only create answers in response to offers
                    if (signal.sdp.type == 'offer') {
                        console.log("Create answer to offer")
                        this.peerConnections[remoteUuid].pc.createAnswer().then(description => this.createdDescription(description, remoteUuid)).catch(this.errorHandler);
                    }
                }).catch(this.errorHandler);

            } else if (signal.ice) {
                console.log("add ice candidate")
                this.peerConnections[remoteUuid].pc.addIceCandidate(new RTCIceCandidate(signal.ice)).catch(this.errorHandler);
            }
        },
        setUpPeer(remoteUuid) {
            this.peerConnections[remoteUuid] = { 'pc': new RTCPeerConnection(this.pcConfig) };
            this.peerConnections[remoteUuid].pc.onicecandidate = event => this.gotIceCandidate(event, remoteUuid);
            this.peerConnections[remoteUuid].pc.ontrack = event => this.gotRemoteStream(event, remoteUuid);
            this.peerConnections[remoteUuid].pc.oniceconnectionstatechange = event => this.checkPeerDisconnect(event, remoteUuid);
        },
        addTrackForAll(stream = this.localStream) {
            for (let remoteUuid in this.peerConnections) {
                this.addTrack(remoteUuid, stream);
                //this.peerConnections[remoteUuid].pc.addStream(this.localStream)

                // this.localStream.getTracks().forEach(track => {
                //     const sender = this.peerConnections[remoteUuid].pc.addTrack(track, this.localStream);
                //     this.peerConnections[remoteUuid]['sender'] = sender;
                // });
            }
        },
        async addTrack(remoteUuid, stream = this.localStream) {
            //let track = this.localStream.getTracks()[0];
            stream.getTracks().forEach((track) => {
                this.peerConnections[remoteUuid].pc.addTrack(track, stream);
            });

            //this.changeVideoCodec('video/AV1', remoteUuid);
            this.changeVideoCodec('video/H264', remoteUuid);

            await this.peerConnections[remoteUuid].pc.createOffer().then(description => this.createdDescription(description, remoteUuid)).catch(this.errorHandler);
        },
        async setVideoParams(remoteUuid, bitrateMbit) {
            for (const sender of this.peerConnections[remoteUuid].pc.getSenders()) {
                if (sender.track && sender.track.kind == 'video') {
                    try {
                        const params = sender.getParameters();
                        console.log(params);

                        if (!params.encodings) {
                            params.encodings = [{}];
                        }

                        params.encodings[0].scaleResolutionDownBy = 1;
                        params.encodings[0].maxBitrate = this.selectedBitrate * 1000;
                        params.encodings[0].adaptivePtime = true;

                        console.log(params);
                        await sender.setParameters(params);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        },
        changeVideoCodec(mimeType, remoteUuid) {
            const transceivers = this.peerConnections[remoteUuid].pc.getTransceivers();
            console.log(transceivers)

            transceivers.forEach((transceiver) => {
                console.log("transceiver:")
                console.log(transceiver)
                //if (transceiver.track) {
                //const kind = transceiver.sender.track.kind;
                const kind = "video";
                let sendCodecs = RTCRtpSender.getCapabilities(kind).codecs;
                let recvCodecs = RTCRtpReceiver.getCapabilities(kind).codecs;

                console.log("CODESC")
                console.log(sendCodecs)
                console.log(recvCodecs)
                console.log("CODESC END")

                if (kind === "video") {
                    sendCodecs = this.preferCodec(sendCodecs, mimeType);
                    recvCodecs = this.preferCodec(recvCodecs, mimeType);
                    try {   // for browsers that do not support this method (firefox)
                        transceiver.setCodecPreferences([...sendCodecs, ...recvCodecs]);
                    } catch (err) {
                        console.log(err);
                    }
                }

                console.log("CODESC REARRANGED")
                console.log(sendCodecs)
                console.log(recvCodecs)
                console.log("CODESC END")
                //}
            });

            //this.peerConnections[remoteUuid].pc.onnegotiationneeded();
        },
        preferCodec(codecs, mimeType) {
            let otherCodecs = [];
            let sortedCodecs = [];

            codecs.forEach((codec) => {
                if (codec.mimeType != 'video/AV1') {
                    if (codec.mimeType === mimeType) {
                        console.log("push codec: " + codec.mimeType)
                        sortedCodecs.push(codec);
                    } else {
                        otherCodecs.push(codec);
                    }
                }
            });

            let a = sortedCodecs.concat(otherCodecs);
            console.log(a)

            return a;
        },
        replaceTrack(lastTrackIds) {
            let track = this.localStream.getTracks()[0];
            for (let remoteUuid in this.peerConnections) {
                const sender = this.getlocalCurrentStreamSender(remoteUuid, lastTrackIds);
                sender.replaceTrack(track);
            }
        },
        getlocalCurrentStreamSender(remoteUuid, lastTrackIds) {
            const senders = this.peerConnections[remoteUuid].pc.getSenders();
            for (const sender of senders) {
                if (sender.track && lastTrackIds.includes(sender.track.id)) {
                    return sender;
                }
            }
            return null;
        },
        gotIceCandidate(event, remoteUuid) {
            if (event.candidate != null) {
                this.webSocketConnection.send(JSON.stringify({ 'ice': event.candidate, 'uuid': this.localUuid, 'dest': remoteUuid }));
            }
        },
        createdDescription(description, remoteUuid) {
            console.log(`got description, remote ${remoteUuid}`);

            //this.changeVideoCodec('video/H264', remoteUuid);
            this.peerConnections[remoteUuid].pc.setLocalDescription(description).then(async () => {
                if (this.isStreaming) {
                    await this.setVideoParams(remoteUuid, 35);
                }
                console.log("localDescription", this.peerConnections[remoteUuid].pc.localDescription);
                this.webSocketConnection.send(JSON.stringify({ 'sdp': this.peerConnections[remoteUuid].pc.localDescription, 'uuid': this.localUuid, 'dest': remoteUuid }));
            }).catch(this.errorHandler);
        },
        gotRemoteStream(event, remoteUuid) {
            console.log(`got remote stream, remote ${remoteUuid}`);
            let track = event.streams[0].getTracks()[0];

            // console.log(event.streams[0].getTracks());
            // console.log(event);
            // console.log(track);

            // setInterval(async () => {
            //     console.log(this.peerConnections[remoteUuid].pc.getSenders()[0].getParameters());
            // }, 5000);

            // event.streams[0].onremovetrack = track => {
            //     console.log(`${track.kind} track was removed.`);
            //     if (!event.streams[0].getTracks().length) {
            //         console.log(`stream ${streams[0].id} emptied (effectively removed).`);
            //     }
            // }

            //console.log("pc-tracks: ", this.peerConnections[remoteUuid].pc.getReceivers())

            const streamId = event.streams[0].id;
            let videoElem = document.getElementById('video_' + streamId);
            if (!videoElem) {
                this.dish.addCamera(streamId, remoteUuid, this.userList[remoteUuid].name);
                this.dish.video(streamId, remoteUuid, () => { }, false);
                videoElem = document.getElementById('video_' + streamId);
                videoElem.volume = 0;
                if (event.streams[0].getTracks().length <= 1) {
                    this.dish.disableVolumeControls(streamId, true);
                }
            }
            videoElem.srcObject = event.streams[0];
        },
        remoteTrackStopped(trackId) {
            console.log(`remote stream stopped, remote ${trackId}`);
            this.dish.removeCameraById(trackId);
        },
        remoteTrackStoppedUUID(remoteUuid) {
            console.log(`remote stream stopped uuid, remote ${remoteUuid}`);
            this.dish.removeCamerasByClassName(remoteUuid);
        },
        stopLocalTrack() {
            console.log("stream ended!")
            for (const trackId of this.trackIds) {
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'stopTrack', 'track': trackId }));
            }
            this.clearEmptySenders();
            this.dish.removeCameraById(this.localUuid);
            this.isStreaming = false;
            this.localStream = null;
            this.localVideoId = null;
            this.trackIds = [];
        },
        clearEmptySenders() {
            for (let remoteUuid in this.peerConnections) {
                const senders = this.peerConnections[remoteUuid].pc.getSenders();
                for (const sender of senders) {
                    if (!sender.track) {
                        this.peerConnections[remoteUuid].pc.removeTrack(sender);
                    }
                }
            }
        },
        checkPeerDisconnect(event, remoteUuid) {
            var state = this.peerConnections[remoteUuid].pc.iceConnectionState;
            console.log(`connection with remote ${remoteUuid} ${state}`);
            if (state === "failed" || state === "closed" || state === "disconnected") {
                delete this.peerConnections[remoteUuid];
            }
        },
        errorHandler(error) {
            console.log(error);
        },
        createUUID() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },
        getNumberOfStreams() {
            let numberOfStreams = 0;
            for (let remoteUuid in this.peerConnections) {
                if (this.peerConnections[remoteUuid].pc && this.peerConnections[remoteUuid].pc.getRemoteStreams()[0] && this.peerConnections[remoteUuid].pc.getRemoteStreams()[0].active) {
                    numberOfStreams++;
                }
            }
            if (this.isStreaming) {
                numberOfStreams++;
            }
            console.log("number of streams:" + numberOfStreams)
            return numberOfStreams;
        },
        videoHasAudio(video) {
            return video.mozHasAudio ||
                Boolean(video.webkitAudioDecodedByteCount) ||
                Boolean(video.audioTracks && video.audioTracks.length);
        },
        showVideoSettingsModal() {
            if (this.isStreaming) {
                this.startScreenCapture();
            } else {
                this.isVideoSettingsModalVisible = true;
            }
        },
        async requestGuestAccount() {
            await api.post('/room/join/guest', {
                roomId: this.$route.params.roomId
            }).then((res) => {
                store.dispatch('setUUID', res.data.uuid)
                store.dispatch('setUser', res.data.name)
                store.dispatch('setRole', res.data.role)
                return true
            }).catch((err) => {
                return false
            });
        },
        async requestNameByUUID(uuid) {
            await api.get('/room/uuid/' + uuid).then((res) => {
                this.userList[uuid] = {
                    'name': res.data.name,
                    'role': res.data.role
                };
                return true
            }).catch((err) => {
                return false
            });
        },
        closeVideoSettingsModal(canceled, maxBitrate, fps) {
            this.isVideoSettingsModalVisible = false;
            if (!canceled) {
                this.selectedBitrate = maxBitrate;
                this.selectedFPS = fps;
                this.startScreenCapture();
            }
        },
        changeUserQualitySettings(uuid) {
            if (store.getters.getRole == 'ROLE_ADMIN') {
                this.selectedQualityUUID = uuid;
                this.isQualitySettingsModalVisible = true;
            }
        },
        closeQualitySettingsModal() {
            this.isQualitySettingsModalVisible = false;
        },
        clearRemoteUser(remoteUuid) {
            if (this.peerConnections[remoteUuid] && this.peerConnections[remoteUuid].pc) {
                this.peerConnections[remoteUuid].pc.close();
            }
            delete this.peerConnections[remoteUuid];
            delete this.userList[remoteUuid];
        },
        loadPcConfig() {
            api.get('/room/ice').then(res => {
                const iceServers = res.data;
                this.pcConfig = { 'iceServers': [] };
                for (const server of iceServers) {
                    const url = `${server.type}:${server.url}`;
                    if (server.type == 'STUN') {
                        this.pcConfig['iceServers'].push({
                            'urls': url
                        })
                    } else {
                        this.pcConfig['iceServers'].push({
                            'urls': url,
                            'username': server.username,
                            'credential': server.credential,
                        })
                    }
                }
            });
        },
        async leaveConnection() {
            this.userLeft = true;

            if (this.isStreaming) {
                this.stopMediaStream();
            }

            if (this.webSocketConnection && this.webSocketConnection.readyState) {
                await this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'left' }));
                this.webSocketConnection.close();
            }
            this.webSocketConnection = undefined;

            for (let remoteUuid in this.peerConnections) {
                if (this.peerConnections[remoteUuid] && this.peerConnections[remoteUuid].pc) {
                    this.peerConnections[remoteUuid].pc.close();
                }
                delete this.peerConnections[remoteUuid];
            }

            this.peerConnections = {};
            this.userList = {};

            if (!store.getters.isLoggedIn) {
                store.dispatch('removeUUID');
                store.dispatch('removeUser');
                store.dispatch('removeRole');
            }

            this.emitter.emit("hideYtInput");
        },
        // Youtube Integration
        playNextYtVideo(videoUrl) {
            this.setYTProgrammaticFlag();
            if (!videoUrl && this.ytPlaylist.length > 0) {
                videoUrl = this.ytPlaylist.shift();
            }

            if (this.ytPlayer) {
                //this.ytPlayer.cueVideoById(videoUrl, 0);
                this.ytPlayer.loadVideoById(videoUrl, 0);
            } else {
                this.createYTScreen(videoUrl);
            }
        },
        addYtVideoToCue(url) {
            if (this.ytPlayer && [1, 2, 3].includes(this.ytPlayer.getPlayerState())) {
                this.ytPlaylist.push(url);
            } else {
                this.playNextYtVideo(url);
            }
        },
        createYTScreen(videoUrl) {
            this.dish.addCamera('yt', 'yt', 'Youtube', 0, true, true);
            this.ytPlayer = new YT.Player('video_yt', {
                videoId: videoUrl,
                host: 'https://www.youtube-nocookie.com',
                playerVars: {
                    start: 0,
                    autoplay: 0,
                    controls: 1,
                    disablekb: 0,
                    modestbranding: 1
                },
                events: {
                    'onStateChange': this.onYTPlayerStateChange,
                    'onPlaybackQualityChange': this.onYTPlayerEvent,
                    'onPlaybackRateChange': this.onYTPlayerEvent,
                    'onError': this.onYTPlayerEvent,
                    'onApiChange': this.onYTPlayerEvent,
                    'onReady': this.onYTPlayerReady
                }
            });
        },
        removeYTScreen() {
            clearInterval(this.ytEventListener);
            this.dish.removeCameraById('yt');
            this.ytPlayer = undefined;
            this.lastYTPlayerTime = -1;
            this.ytLatestSeekTime = 0;
            this.ytPlaylist = [];
        },
        handleYtMessage(message) {
            console.log("ytmessage:", message)
            if (this.ytPlayer) {
                console.log("playerState:", this.ytPlayer.getPlayerState())
            }

            if (message.action == 'play' && this.ytPlayer.getPlayerState() != 1) {
                this.setYTProgrammaticFlag();
                this.ytPlayer.playVideo();
                this.ytPaused = false;
            } else if (message.action == 'pause') {
                this.setYTProgrammaticFlag();
                this.ytPlayer.pauseVideo();
                this.ytPaused = true;
                this.lastYTPlayerTime = message.currentPlayerTime;
                this.ytPlayer.seekTo(message.currentPlayerTime, true);
            } else if (message.action == 'seekTo' && Math.abs(message.currentPlayerTime - this.ytPlayer.getCurrentTime()) > 0.1) {
                this.setYTProgrammaticFlag();
                this.lastYTPlayerTime = message.currentPlayerTime;
                this.ytPlayer.seekTo(message.currentPlayerTime, true);
            } else if (message.action == 'addToPlaylist') {
                this.addYtVideoToCue(message.url);
            } else if (message.action == 'removeYTScreen') {
                this.removeYTScreen();
            }
        },
        setYTProgrammaticFlag() {
            this.isProgrammaticYT = true;
            if (this.programmaticYTTimeout) {
                clearTimeout(this.programmaticYTTimeout);
            }
            this.programmaticYTTimeout = setTimeout(() => {
                this.isProgrammaticYT = false;
            }, 200);
        },
        onYTPlayerReady(event) {
            this.ytEventListener = setInterval(() => {
                const currentTime = this.ytPlayer.getCurrentTime();
                if (this.lastYTPlayerTime !== -1 && Math.abs(currentTime - this.lastYTPlayerTime) > 1.0 && !this.isProgrammaticYT) {
                    console.log('User changed the video position');
                    this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'yt', 'action': 'seekTo', 'currentPlayerTime': currentTime }));
                    this.ytLatestSeekTime = Date.now();
                    if (!this.ytPaused && this.ytPlayer.getPlayerState() == 2) {
                        this.ytPlayer.playVideo();
                    }
                }
                this.lastYTPlayerTime = currentTime;
            }, 200);
        },
        onYTPlayerStateChange(event) {
            if (event.data === YT.PlayerState.PAUSED && !this.isProgrammaticYT) {
                setTimeout(() => {
                    if (Date.now() - this.ytLatestSeekTime > 250) {
                        this.ytPaused = true;
                        console.log('User paused the video');
                        this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'yt', 'action': 'pause', 'currentPlayerTime': this.ytPlayer.getCurrentTime() }));
                    }
                }, 200);
            }
            if (event.data === YT.PlayerState.PLAYING && !this.isProgrammaticYT) {
                this.ytPaused = false;
                console.log('User played the video');
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'yt', 'action': 'play' }));
            }
            if (event.data === YT.PlayerState.ENDED && !this.isProgrammaticYT) {
                if (this.ytPlaylist.length > 0) {
                    this.playNextYtVideo();
                } else {
                    this.removeYTScreen();
                }
                console.log('Video Ended');
            }
        },
        onYTPlayerEvent(event) {
            this.isProgrammaticYT = false;
        },
        registerYtEventListener() {
            this.emitter.on("addYoutubeVideo", (url) => {
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'yt', 'action': 'addToPlaylist', 'url': url }));
                this.addYtVideoToCue(url);
            });
            this.emitter.on("removeYTScreen", () => {
                this.webSocketConnection.send(JSON.stringify({ 'uuid': this.localUuid, 'dest': 'all', 'type': 'yt', 'action': 'removeYTScreen' }));
                this.removeYTScreen();
            });
        }
    }
}

</script>