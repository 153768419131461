import api from "./api";
import store from '@/store'
import router from '@/router'

class AuthService {
    async login(username, password, redirectTo) {
        return await api.post('/account/signin', {
            username: username,
            password: password
        }).then((res) => {
            if (res.data.jwtToken) {
                store.dispatch('setUUID', res.data.uuid)
                store.dispatch('setUser', res.data.name)
                store.dispatch('setRole', res.data.role)
                store.dispatch('setToken', res.data.jwtToken)
                //store.dispatch('setRefreshToken', res.data.refresh_token)
                router.replace(redirectTo || '/');
            }
            return true
        }).catch((err) => {
            return false
        });
    }

    async signup(username, password, redirectTo) {
        return await api.post('/account/signup', {
            username: username,
            password: password
        }).then((res) => {
            return res.status === 200;
        }).catch((err) => {
            return false
        });
    }

    logout() {
        store.dispatch('removeUUID')
        store.dispatch('removeUser')
        store.dispatch('removeRole')
        store.dispatch('removeToken')
        store.dispatch('removeRefreshToken')
        window.sessionStorage.clear()
        router.push("/")
    }

    async changepassword(passwordOld, passwordNew) {
        return await api.post('/account/changepassword', {
            passwordOld: passwordOld,
            passwordNew: passwordNew
        }).then((res) => {
            return res.status === 200;
        }).catch((err) => {
            return false
        });
    }

    async adminChangePassword(passwordNew, uuid) {
        return await api.post('/account/adminchangepassword/' + uuid, {
            passwordNew: passwordNew
        }).then((res) => {
            return res.status === 200;
        }).catch((err) => {
            return false
        });
    }
}

export default new AuthService();