import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    plugins: [createPersistedState({
        storage: window.localStorage,
    })],
    state: {
        uuid: null,
        user: null,
        role: null,
        token: null,
        refresh_token: null,
        quality_fps: null,
        quality_bitrate: null
    },
    mutations: {
        UPDATE_UUID(state, uuid) {
            state.uuid = uuid
        },
        UPDATE_USER(state, user) {
            state.user = user
        },
        UPDATE_ROLE(state, role) {
            state.role = role
        },
        UPDATE_TOKEN(state, token) {
            state.token = token
        },
        UPDATE_REFRESH_TOKEN(state, refresh_token) {
            state.refresh_token = refresh_token
        },
        UPDATE_QUALITY_FPS(state, quality_fps) {
            state.quality_fps = quality_fps
        },
        UPDATE_QUALITY_BITRATE(state, quality_bitrate) {
            state.quality_bitrate = quality_bitrate
        }
    },
    actions: {
        setUUID(context, uuid) {
            context.commit('UPDATE_UUID', uuid)
        },
        removeUUID(context) {
            context.commit('UPDATE_UUID', null)
        },
        setUser(context, user) {
            context.commit('UPDATE_USER', user)
        },
        removeUser(context) {
            context.commit('UPDATE_USER', null)
        },
        setRole(context, role) {
            context.commit('UPDATE_ROLE', role)
        },
        setQualityFPS(context, quality_fps) {
            context.commit('UPDATE_QUALITY_FPS', quality_fps)
        },
        setQualityBitrate(context, quality_bitrate) {
            context.commit('UPDATE_QUALITY_BITRATE', quality_bitrate)
        },
        removeRole(context) {
            context.commit('UPDATE_ROLE', null)
        },
        setToken(context, token) {
            context.commit('UPDATE_TOKEN', token)
        },
        removeToken(context) {
            context.commit('UPDATE_TOKEN', null)
        },
        setRefreshToken(context, refresh_token) {
            context.commit('UPDATE_REFRESH_TOKEN', refresh_token)
        },
        removeRefreshToken(context) {
            context.commit('UPDATE_REFRESH_TOKEN', null)
        },
    },
    getters: {
        getUUID(state) {
            return state.uuid;
        },
        getUser(state) {
            return state.user;
        },
        getRole(state) {
            return state.role;
        },
        getToken(state) {
            return state.token;
        },
        getQualityFPS(state) {
            return state.quality_fps;
        },
        getQualityBitrate(state) {
            return state.quality_bitrate;
        },
        isLoggedIn(state) {
            return !!state.token;
        }
    }
});