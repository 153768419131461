<template>
    <div class="content-wrapper">
        <div class="col-12 row justify-content-center middle">
            <router-link v-if="$store.getters.isLoggedIn" :to="{
                name: 'Room', params: { roomId: roomId }
            }" style="margin: auto;">
                <button class="btn btn-connect btn-lg">Create Room</button>
            </router-link>
            <router-link v-if="!$store.getters.isLoggedIn" :to="{ name: 'Login' }" style="margin: auto;">
                <button class="btn btn-connect btn-lg">Log In</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateRoom',
    data() {
        return {
            roomId: false,
        }
    },
    mounted() {
        this.roomId = this.createRandomId();
    },
    methods: {
        createRandomId() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }
            return s4() + s4() + s4() + s4() + s4()
        }
    }
}
</script>
