<template>
    <UserContent />
</template>

<script>

import UserContent from '@/components/UserContent_multi_client.vue'

export default {
    components: {
        UserContent
    },
    name: 'Room'
}
</script>
