import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Room from '@/views/Room.vue'
import CreateRoom from '@/views/CreateRoom.vue'
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import Admin from '@/views/Admin.vue'

const routes = [
  {
    path: '/',
    name: 'CreateRoom',
    component: CreateRoom,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/room/:roomId',
    name: 'Room',
    component: Room,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      role: 'ROLE_ADMIN'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if ((to.meta.requiresAuth && !store.getters.isLoggedIn) || (to.meta.role == 'ROLE_ADMIN' && store.getters.getRole != 'ROLE_ADMIN')) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
});

export default router
