<template>
    <div class="content-wrapper">
        <div class="col-10" style="margin: 40px auto">
            <div class="col-sm-6">
                <h4 class="headerAdmin">User Overview </h4>
                <div>
                    <input type="checkbox" id="deleteMode" name="deleteMode" class="form-check-input" v-model="globalDelete" />
                    <label for="deleteMode" class="form-check-label whiteImportant">Delete</label>
                </div>
            </div>
            <table id="userTable" class="display dataTableColor" style="width:100%">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>UUID</th>
                        <th>Role</th>
                        <th>is Active</th>
                        <th>Created at</th>
                        <th>Delete</th>
                    </tr>
                </thead> 
                <tbody>
                    <tr v-for="dsyncUser in allUsersInformation">
                        <td>
                            <span class="navbar-text user-hover" style="cursor: pointer;" @click="changeUserPassword(dsyncUser.name, dsyncUser.uuid)">
                                {{ dsyncUser.name }}
                            </span>
                        </td>
                        <td>{{ dsyncUser.uuid }}</td>
                        <td>{{ dsyncUser.role }}</td>
                        <td valign="top" class="center">
                            <div style="text-align:center; vertical-align:middle">
                                <input type="checkbox" :id="dsyncUser.uuid" :name="dsyncUser.uuid" class="form-check-input" :checked="dsyncUser.active" @change="changeUserActivation($event, dsyncUser.uuid)"/>
                            </div>
                        </td> 
                        <td>{{ dsyncUser.createdAt }}</td>
                        <td valign="top" class="center">
                            <svg-icon type="mdi" style="cursor: pointer;" :path="icons['mdiTrashCan']" @click="deleteUser(dsyncUser.uuid)"></svg-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-10" style="margin: 40px auto">
            <div class="col-sm-6">
                <h4 class="headerAdmin">Room Overview</h4>
            </div>
            <table id="roomTable" class="display dataTableColor" style="width:100%">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>IP-Address</th>
                        <th>Room-Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="roomUser in roomUserInformation">
                        <td>{{ roomUser.name }}</td>
                        <td>{{ roomUser.ip }}</td>
                        <td><router-link class="cleanwhite" :to="'/room/' + roomUser.roomId">{{ roomUser.roomId }}</router-link></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <span v-if="userChangePassword">
            <div class="col-10" style="margin: 40px auto">
                <div class="col-sm-6">
                    <h4 class="headerAdmin">Change User Password</h4>
                    <form class="form-group" v-if="!isChangePasswordSuccessful" name="form" @submit.prevent="handleChangePassword">
                        <label for="newPassword" class="label-login">Set new password for {{ userToChangePassword }}</label>
                        <input id="new-Password" class="form-control input-login" type="text" :value="randomPassword" required>
                        <div class="form-group">
                            <button class="btn btn-login btn-block">Change Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import api from "@/services/api";
import SvgIcon from '@jamescoyle/vue-icon';
import AuthService from '@/services/auth'
import { mdiTrashCan } from '@mdi/js';

export default {
  name: 'Admin',
  components: {
    SvgIcon
  },
  data() {
        return {
            roomUserInformation: undefined,
            allUsersInformation: undefined,
            globalDelete: false,
            userTable: undefined,
            roomTable: undefined,
            userChangePassword: false,
            newPassword: '',
            userToChangePassword: '',
            uuidToChangePassword: '',
            isChangePasswordSuccessful: undefined,
            randomPassword: '',
            icons: {
                'mdiTrashCan': mdiTrashCan
            }
        }
    },
  mounted() {
    this.getRoomsInformation();
    this.getAllUsersInformation();
    },
  updated() {
  },
  methods: {
    createUserTable() {
        this.userTable = new DataTable('#userTable', {
            layout: {
                bottomEnd: {
                    paging: {
                        boundaryNumbers: false
                    }
                }
            }
        });
        
    },
    createRoomTable() {
        this.roomTable = new DataTable('#roomTable', {
            layout: {
                bottomEnd: {
                    paging: {
                        boundaryNumbers: false
                    }
                }
            }
        });
    },
    async getRoomsInformation() {
        await api.get('/room/users'
        ).then((res) => {
            this.roomUserInformation = res.data
            
        }).catch((err) => {
            console.log(err)
        });
        this.createRoomTable(); 
    },
    async getAllUsersInformation() {
        await api.get('/account/users'
        ).then((res) => {
            this.allUsersInformation = res.data
             
        }).catch((err) => {
            console.log(err)
        });
        this.createUserTable();
    },
    changeUserActivation(event, uuid) {
        if(event.target.checked) {
            api.post('/account/activate/' + uuid)
        } else {
            api.post('/account/deactivate/' + uuid)
        }
    },
    async deleteUser(uuid) {
        if(this.globalDelete) {
            await api.delete('/account/' + uuid)
            this.userTable.destroy();
            this.getAllUsersInformation();
        }
    },
    changeUserPassword(name, uuid) {
        this.generateRandomPassword();
        this.userToChangePassword = name;
        this.userChangePassword = true;
        this.uuidToChangePassword = uuid;
        
    },
    generateRandomPassword() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        this.randomPassword = s4() + s4() + s4()
    },
    async handleChangePassword() {
        AuthService.adminChangePassword(this.randomPassword, this.uuidToChangePassword)
        this.userChangePassword = false;
        this.randomPassword = '';
        this.uuidToChangePassword = '';
    }
  }
}
</script>

<style scoped>

select,
option {
    background-color: #414141 !important;
    border-color: #414141 !important;
    color: #414141 !important;
}

</style>