<template>
    <div class="modal-backdrop" @click.self="close">
        <div class="modal">
            <section class="modal-body">
                <p>Available Bitrates</p>
                <div class="form-group">
                    <div v-for="options in showOptions" class="form-check">
                        <input class="form-check-input" type="checkbox" :id="options.bitrate"
                            :disabled="options.source == 'GROUP'" :checked="!options.notChecked"
                            @change="changeBitrateOption($event, options.bitrate)">
                        <label class="form-check-label" :for="options.bitrate">
                            {{ options.bitrate }} kbit/s
                        </label>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import api from "@/services/api";
import store from '@/store';

export default {
    name: 'CustomBitrate',
    props: ['uuid'],
    data() {
        return {
            qualityOptions: undefined,
            allPossibleOptions: [500, 1000, 2000, 5000, 10000, 20000, 25000, 30000, 35000, 40000, 50000, 60000, 70000, 80000, 100000, 150000, 250000]
        }
    },
    computed: {
        showOptions() {
            let options = []
            for (let value of this.allPossibleOptions) {
                let containsOption = false;
                if (this.qualityOptions) {
                    for (let userOption of this.qualityOptions) {
                        if (value == userOption.bitrate) {
                            options.push(userOption)
                            containsOption = true;
                            break;
                        }
                    }
                }
                if (!containsOption) {
                    options.push({ 'bitrate': value, 'notChecked': true })
                }
            }
            return options
        }
    },
    mounted() {
        this.getVideoQualityOptions();
    },
    methods: {
        async getVideoQualityOptions() {
            this.qualityOptions = (await api.get('/quality/admin/' + this.uuid)).data;
        },
        changeBitrateOption(event, bitrate) {
            if (event.target.checked) {
                api.put(`/quality/${this.uuid}/${bitrate}`);
            } else {
                api.delete(`/quality/${this.uuid}/${bitrate}`);
            }
        },
        close() {
            this.$emit('close', true);
        },
        next() {
            this.$emit('close', false, this.selectedBitrate, this.selectedFPS);
        }
    },
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #1F1F1F;
    border-color: #444746;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    overflow-x: auto;
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #444746;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 20px 50px 20px 20px;
}

.form-group {
    margin-left: .8rem;
}

.form-check {
    margin: 2px 0;
}

p {
    color: #ffffff;
    font-weight: bold;
}

label {
    color: #ffffff;
}
</style>