<template>
  <div class="content-wrapper">
    <div class="login-center">

      <div class="login-box">
        <div class="card-body login-card-body">
          <div class="title-login">Sign Up</div>

          <form v-if="!isSignupSuccessful" name="form" @submit.prevent="handleSignup">
            <div class="form-group">
              <label for="username" class="label-login">Username</label>
              <input v-model="username" type="text" class="form-control input-login" id="username" required>
            </div>
            <div class="form-group">
              <label for="password" class="label-login">Password</label>
              <input v-model="password" id="password" type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  
                class="form-control input-login"
                :oninput="checkPassword" required>
              <div id="message">
                <p class="label-pwcheck">Password must contain the following:</p>
                <p id="letter" class="label-pwcheck invalid">A <b>lowercase</b> letter</p>
                <p id="capital" class="label-pwcheck invalid">A <b>CAPITAL</b> letter</p>
                <p id="number" class="label-pwcheck invalid">A <b>numb3r</b></p>
                <p id="length" class="label-pwcheck invalid">Minimum <b>8 characters</b></p>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="label-login">Confirm Password</label>
              <input v-model="confirmPassword" id="confirmPassword" type="password" class="form-control input-login"
                :oninput="checkPassword" required>
            </div>
            <div class="form-group">
              <button class="btn btn-login btn-block">Sign Up</button>
            </div>
          </form>

          <div v-if="isSignupSuccessful">
            <div class="signup-text">The account has been successfully created but still needs to be activated. This
              process may take some time.
            </div>
            <router-link :to="{ name: 'CreateRoom' }">
              <button class="btn btn-login btn-block">Go Back</button>
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AuthService from '@/services/auth'

export default {
  name: 'Sign Up',
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      isSignupSuccessful: undefined
    };
  },
  methods: {
    async handleSignup() {
      if (!this.checkPassword()) {
        return;
      }

      this.isSignupSuccessful = await AuthService.signup(this.username, this.password);
      if (!this.isSignupSuccessful) {
        this.username = '';
        this.password = '';
        this.confirmPassword = '';
      }
    },
    checkPassword() {
      var letter = document.getElementById("letter");
      var capital = document.getElementById("capital");
      var number = document.getElementById("number");
      var length = document.getElementById("length");
      let validPassword = true;

      var lowerCaseLetters = /[a-z]/g;
      if(this.password.match(lowerCaseLetters)) {
        letter.classList.remove("invalid");
        letter.classList.add("valid");
      } else {
        letter.classList.remove("valid");
        letter.classList.add("invalid");
        validPassword = false;
      }

      var upperCaseLetters = /[A-Z]/g;
      if(this.password.match(upperCaseLetters)) {
        capital.classList.remove("invalid");
        capital.classList.add("valid");
      } else {
        capital.classList.remove("valid");
        capital.classList.add("invalid");
        validPassword = false;
      }

      var numbers = /[0-9]/g;
      if(this.password.match(numbers)) {
        number.classList.remove("invalid");
        number.classList.add("valid");
      } else {
        number.classList.remove("valid");
        number.classList.add("invalid");
        validPassword = false;
      }

      if(this.password.length >= 8) {
        length.classList.remove("invalid");
        length.classList.add("valid");
      } else {
        length.classList.remove("valid");
        length.classList.add("invalid");
        validPassword = false;
      }

      if (this.password && this.confirmPassword) {

        // check if password and confirm password are equal
        const confirmPasswordElement = document.getElementById('confirmPassword');
        if (this.password == this.confirmPassword) {
          confirmPasswordElement.classList.remove('red-border');
        } else {
          confirmPasswordElement.classList.add('red-border');
          validPassword = false;
        }
      }

      return validPassword;
    }
  }
}
</script>
@/services/auth