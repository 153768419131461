<template>
    <div class="content-wrapper">
        <div class="login-center">

            <div class="login-box">
                <div class="card-body login-card-body">
                    <div class="title-login">Change Password</div>

                    <form v-if="!isChangePasswordSuccessful" name="form" @submit.prevent="handleChangePassword">
                        <div class="form-group">
                            <label for="oldPassword" class="label-login">Old Password</label>
                            <input v-model="oldPassword" type="password" class="form-control input-login" id="username"
                                required>
                        </div>
                        <div class="form-group">
                            <label for="newPassword" class="label-login">New Password</label>
                            <input v-model="newPassword" type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                class="form-control input-login" :oninput="checkPassword" required>
                            <div id="message">
                                <p class="label-pwcheck">Password must contain the following:</p>
                                <p id="letter" class="label-pwcheck invalid">A <b>lowercase</b> letter</p>
                                <p id="capital" class="label-pwcheck invalid">A <b>CAPITAL</b> letter</p>
                                <p id="number" class="label-pwcheck invalid">A <b>numb3r</b></p>
                                <p id="length" class="label-pwcheck invalid">Minimum <b>8 characters</b></p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="newPasswordRepeat" class="label-login">Confirm New Password</label>
                            <input v-model="newPasswordRepeat" id="newPasswordRepeat" type="password" class="form-control input-login" :oninput="checkPassword"
                                required>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-login btn-block">Change Password</button>
                        </div>
                    </form>
                    <div v-if="isChangePasswordSuccessful">
                        <div class="signup-text">The password has been successfully changed.</div>
                        <router-link :to="{ name: 'CreateRoom' }">
                            <button class="btn btn-login btn-block">Go Back</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AuthService from '@/services/auth'

export default {
    name: 'Change Password',
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            isChangePasswordSuccessful: undefined
        };
    },
    methods: {
        async handleChangePassword() {
            if (!this.checkPassword()) {
                return;
            }

            this.isChangePasswordSuccessful = await AuthService.changepassword(this.oldPassword, this.newPassword);
            if(!this.isChangePasswordSuccessful) {
                this.oldPassword = '';
                this.newPassword = '';
                this.newPasswordRepeat = '';
            }
        },
        checkPassword() {
            var letter = document.getElementById("letter");
            var capital = document.getElementById("capital");
            var number = document.getElementById("number");
            var length = document.getElementById("length");
            let validPassword = true;

            var lowerCaseLetters = /[a-z]/g;
            if(this.newPassword.match(lowerCaseLetters)) {
                letter.classList.remove("invalid");
                letter.classList.add("valid");
            } else {
                letter.classList.remove("valid");
                letter.classList.add("invalid");
                validPassword = false;
            }

            var upperCaseLetters = /[A-Z]/g;
            if(this.newPassword.match(upperCaseLetters)) {
                capital.classList.remove("invalid");
                capital.classList.add("valid");
            } else {
                capital.classList.remove("valid");
                capital.classList.add("invalid");
                validPassword = false;
            }

            var numbers = /[0-9]/g;
            if(this.newPassword.match(numbers)) {
                number.classList.remove("invalid");
                number.classList.add("valid");
            } else {
                number.classList.remove("valid");
                number.classList.add("invalid");
                validPassword = false;
            }

            if(this.newPassword.length >= 8) {
                length.classList.remove("invalid");
                length.classList.add("valid");
            } else {
                length.classList.remove("valid");
                length.classList.add("invalid");
                validPassword = false;
            }

            if (this.newPassword && this.newPasswordRepeat) {

                // check if password and confirm password are equal
                const newPasswordRepeat = document.getElementById('newPasswordRepeat');
                if (this.newPassword == this.newPasswordRepeat) {
                    newPasswordRepeat.classList.remove('red-border');
                } else {
                    newPasswordRepeat.classList.add('red-border');
                validPassword = false;
                }
            }

        return validPassword;
        }
    }
}
</script>
@/services/auth