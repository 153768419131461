<template>
    <div class="modal-backdrop" @click.self="close">
        <div class="modal">
            <section class="modal-body">
                <div class="form-group">
                    <label for="maxBitrateSelector" class="text-white">Max Bitrate</label>
                    <select v-model="selectedBitrate" class="form-control" id="maxBitrateSelector"
                        @change="selectBitrate">
                        <option v-for="maxBitrate in maxBitrateOptions" :value="maxBitrate">{{ maxBitrate }} kbit/s
                        </option>
                        <option v-if="showUnlimited" value="250000">Unlimited</option>
                    </select>
                </div>
                <label for="fpsSelector" class="text-white">FPS</label><br>
                <div class="btn-group" role="group" id="fpsSelector">
                    <button v-for="fps in fpsOptions" type="button" class="btn btn-secondary" :value="fps"
                        @click="selectFPSOption">{{ fps }}
                        FPS</button>
                </div>
            </section>

            <footer class="modal-footer">
                <button type="button" class="btn btn-footer" @click="close">
                    Cancel
                </button>
                <button type="button" class="btn btn-footer" style="margin-right: 0px;" @click="next">
                    Next
                </button>
            </footer>
        </div>
    </div>
</template>

<script>

import api from "@/services/api";
import store from '@/store';

export default {
    name: 'VideoSettingsModal',
    data() {
        return {
            maxBitrateOptions: [],
            fpsOptions: [5, 30, 60],
            selectedBitrate: 10000,
            selectedFPS: 30,
            showUnlimited: false
        }
    },
    mounted() {
        this.showUnlimited = (store.getters.getRole == 'ROLE_ADMIN');

        this.selectedBitrate = store.getters.getQualityBitrate ?? this.selectedBitrate;
        this.selectedFPS = store.getters.getQualityFPS ?? this.selectedFPS;

        this.getVideoQualityOptions();
        this.setSelectedFPSStyle();
    },
    methods: {
        selectBitrate() {
            store.dispatch('setQualityBitrate', this.selectedBitrate);
        },
        selectFPSOption(event) {
            this.selectedFPS = event.srcElement.value;
            store.dispatch('setQualityFPS', this.selectedFPS);
            this.setSelectedFPSStyle();
        },
        setSelectedFPSStyle() {
            for (const element of document.getElementById('fpsSelector').children) {
                if (element.value == this.selectedFPS) {
                    element.classList.add('fps-selected');
                } else {
                    element.classList.remove('fps-selected');
                }
            }
        },
        close() {
            this.$emit('close', true);
        },
        next() {
            this.$emit('close', false, this.selectedBitrate, this.selectedFPS);
        },
        async getVideoQualityOptions() {
            let videoQuality = (await api.get('/quality/' + store.getters.getUUID)).data;
            for (let quality of videoQuality) {
                this.maxBitrateOptions.push(quality.bitrate);
            }
            if (!this.maxBitrateOptions.includes(Number(this.selectedBitrate))) {
                this.selectedBitrate = 10000;
            }
        }
    },
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #1F1F1F;
    border-color: #444746;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    overflow-x: auto;
    display: flex;
    width: auto;
    height: auto;
    flex-direction: column;
    position: fixed;
    width: 25rem;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #444746;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn.btn-footer {
    margin: 5px;
}

.btn {
    color: #fff;
    background-color: #414141;
    border-color: #414141;
}

select,
option {
    background-color: #414141 !important;
    border-color: #414141 !important;
    color: #ffffff !important;
}

Div.btn-group>button:focus,
.fps-selected {
    background: #272727;
}

Div.btn-group>button:focus,
select:focus {
    box-shadow: none !important;
    outline: 0;
}
</style>