<template>
  <Header />
  <router-view />
</template>

<script>

import Header from '@/components/Header.vue'
import Room from '@/views/Room.vue'

export default {
  components: {
    Header, Room
  },
  mounted() {
    document.title = "DSync Stream"
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentUser() {
      return false;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
}

nav a.router-link-exact-active {
  color: #61abd1 !important;
}
</style>
