<template>
  <nav class="navbar navbar-expand-lg navbar-dark-text bg-dark-nav">
    <router-link :to="{ name: 'CreateRoom' }">
      <a class="navbar-brand">
        <div class="navbar-logo"><img class="navbar-logo-img" src="/favicon.png"></div>
        <div class="navbar-logo-text">DSync Stream</div>
      </a>
    </router-link>
    <div v-show="showYtInput" class="nav-center">
      <input id="ytUrlInput" class="dark-input" placeholder="Enter Youtube URL here">
    </div>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
      </ul>
      <router-link v-if="storeStateLoggedIn" :to="{ name: 'ChangePassword' }">
        <span class="navbar-text user-hover" :class="$store.getters.getRole.toLowerCase()">
          {{ $store.state.user }}
        </span>
      </router-link>
      <span v-if="!storeStateLoggedIn && $store.state.user" class="navbar-text"
        :class="$store.getters.getRole.toLowerCase()">
        {{ $store.state.user }}
      </span>
      <router-link v-if="$store.getters.getRole == 'ROLE_ADMIN'" :to="{ name: 'Admin' }">
        <svg-icon class="admin-icon" type="mdi" :path="icons['mdiSecurity']"></svg-icon>
      </router-link>
      <router-link v-if="!storeStateLoggedIn" :to="{ name: 'Signup' }">
        <button class="btn btn-header btn-gray">Sign Up</button>
      </router-link>
      <router-link v-if="!storeStateLoggedIn" :to="{ name: 'Login' }">
        <button class="btn btn-header">Log In</button>
      </router-link>
      <button v-if="storeStateLoggedIn" class="btn btn-header" @click="logout">Log Out</button>
    </div>
  </nav>
</template>

<script>

import store from '@/store'
import AuthService from '@/services/auth'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiSecurity } from '@mdi/js';

export default {
  props: ['title'],
  name: 'Header',
  components: {
    SvgIcon
  },
  data() {
    return {
      isLoggedIn: undefined,
      showYtInput: false,
      icons: {
        'mdiSecurity': mdiSecurity
      }
    }
  },
  computed: {
    storeStateLoggedIn() {
      return store.getters.isLoggedIn;
    }
  },
  mounted() {
    this.registerYtUrlInput();
  },
  methods: {
    logout() {
      AuthService.logout();
      this.isLoggedIn = false;
    },
    registerYtUrlInput() {
      const inputEle = $('#ytUrlInput');
      inputEle.on('keyup', (e) => {
        if (e.keyCode == 13) {
          console.log(this.youtubeParser(inputEle.val()));
          this.emitter.emit("addYoutubeVideo", this.youtubeParser(inputEle.val()));
          inputEle.val('');
        }
      });

      this.emitter.on("showYtInput", () => {
        this.showYtInput = true;
      });
      this.emitter.on("hideYtInput", () => {
        this.showYtInput = false;
      });
    },
    youtubeParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match && match[7].length == 11) ? match[7] : false;
    }
  }
}
</script>
