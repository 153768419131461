<template>
  <div class="content-wrapper">
    <div class="login-center">

      <div class="login-box">
        <div class="card-body login-card-body">
          <div class="title-login">Log In</div>

          <form name="form" @submit.prevent="handleLogin">
            <div v-if="falseLogin" class="signup-text signup-error">Wrong Username or Password, please try again.<br> If this error persists, please contact an admin.</div>
            <div class="form-group">
              <label for="username" class="label-login">Username</label>
              <input v-model="username" type="text" class="form-control input-login" id="username" required>
            </div>
            <div class="form-group">
              <label for="password" class="label-login">Password</label>
              <input v-model="password" type="password" class="form-control input-login" required>
            </div>
            <div class="form-group">
              <button class="btn btn-login btn-block">Log In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AuthService from '@/services/auth'

export default {
  name: 'Log In',
  data() {
    return {
      username: '',
      password: '',
      isLoginSuccessful: undefined,
      falseLogin: false,
    };
  },
  methods: {
    async handleLogin() {
      this.isLoginSuccessful = await AuthService.login(this.username, this.password);
      if (!this.isLoginSuccessful) {
        this.falseLogin = true;
        this.username = '';
        this.password = '';
      }
    }
  }
}
</script>